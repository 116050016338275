@import "../../../../styleFunctions/mixins";

.bannerContainer {
    margin-top: -12.6rem;
    @include backgroundImage($image: url(../../../../assets/pageBanner.jpg));
    @include flexContainer;
    min-height: 50rem;
    position: relative;
    background-position: center;
    .bannerInner {
        width: 100%;
        z-index: 2;
        // position: relative;
        h2 {
            color: var(--secondaryColor);
            text-align: center;
            font-size: 6rem;
        }
    }
}

@media only screen and (max-width: 600px) {
    .bannerContainer {
        align-items: flex-end;
        padding-bottom: 6rem;
    }
}