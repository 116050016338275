.coupon__wrap {
  display: flex;
  flex-flow: row;
  width: 100%;
  border-radius: 10px;
  .coupon__title {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 35%;
    padding: 10px;
    @media (max-width: 991px) {
      width: 30%;
    }
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      right: -10px;
      width: 15px;
      height: 15px;
      background-color: #fff;
      border-radius: 100%;
    }
    &:after {
      content: "";
      position: absolute;
      top: -11px;
      right: -10px;
      width: 20px;
      height: 10px;
      background-color: #ffffff;
    }
    .couple__category {
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: 700;
    }
    .coupon__max {
      font-size: 11px;
    }
  }
  .coupon__detail {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    flex: 0 0 auto;
    width: 65%;
    padding: 10px 10px 10px 15px;
    @media (max-width: 991px) {
      width: 70%;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: -10px;
      left: -10px;
      width: 15px;
      height: 15px;
      background-color: #fff;
      border-radius: 100%;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: -11px;
      left: -10px;
      width: 20px;
      height: 10px;
      background-color: #ffffff;
    }
    .coupon__price {
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.5px;
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }
    .coupon__info {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      span {
        margin-bottom: 5px;
        font-size: 11px;
        text-align: left;
      }
    }
  }
  .coupon__border {
    position: absolute;
    top: 50%;
    left: 0;
    width: 2px;
    height: calc(100% - 30px);
    transform: translateY(-50%);
  }
}

.coupon__wrap {
    border: 1px solid var(--accentColor);
    background-color: #fff;
    .coupon__title,
    .coupon__detail {
      &:before {
        border: 1px solid var(--accentColor);
      }
    }
    .coupon__max,
    .couple__category {
      color: var(--accentColor);
    }
    .coupon__price {
      color: var(--accentColor);
    }
    .coupon__info span {
      color: var(--accentColor);
    }
    .coupon__condition a {
      color: var(--accentColor);
      background-color: rgba(var(--blueRGB), 0.15);
    }
    .coupon__btn a {
      background-color: var(--accentColor);
    }
    .coupon__border {
      border-left: 1px dashed var(--accentColor);
    }
  }
  
