@import "../../styleFunctions/mixins";

.contactPage {
  margin-top: -12.6rem;
  .contactSection {
    @include backgroundImage($image: url(../../assets/contactBg.jpg));
    background-attachment: fixed;
    @include flexContainer($gap: 0);
    min-height: 90rem;
    > div {
      padding-block-start: 10rem;
      padding-block-end: 10rem;
      > div {
        justify-content: center;
        .contactBlock {
          @include flexContainer;
          flex-wrap: wrap;
          > div {
            width: 100%;
            &:nth-of-type(1) {
              flex: 1 0 300px;
              background-color: var(--whiteColor);
              padding: 3rem;
              min-height: 60rem;
              .smallTxt {
                font-size: 1.4rem;
                color: var(--secondaryColor);
              }
              h2 {
                position: relative;
                color: var(--secondaryColor);
                &:after {
                  content: "";
                  width: 10rem;
                  height: 0.3rem;
                  background-color: var(--accentColor);
                  position: absolute;
                  left: 0;
                  bottom: -1.2rem;
                }
              }
            }
            &:nth-of-type(2) {
              flex: 1 0 200px;
              border: 1rem solid var(--accentColor);
              border-width: 1rem 1rem 1rem 0;
              padding: 4rem 0rem 4rem 4rem;
              h2 {
                position: relative;
                color: var(--secondaryColor);
                &:after {
                  content: "";
                  width: 6rem;
                  height: 0.3rem;
                  background-color: var(--accentColor);
                  position: absolute;
                  left: 0;
                  bottom: -1.2rem;
                }
              }
              .timings {
                @include flexContainer($alignItems: flex-start, $gap: 0rem);
                flex-direction: column;
                margin-block: 3rem;
                > div {
                  width: 100%;
                  display: grid;
                  grid-template-columns: 20% 80%;
                  p {
                    font-size: 1.7rem;
                    color: var(--secondaryColor);
                  }
                }
              }
              .followus {
                margin-top: 3rem;
                @include flexContainer($justifyContent: flex-start);
                a {
                  &:hover {
                    svg {
                      color: var(--accentColor);
                    }
                  }
                  svg {
                    color: var(--secondaryColor);
                    transition: all 0.3s ease-in-out;
                  }
                }
              }
              .contactDetailsBox {
                margin-block: 3rem !important;
                @include flexContainer($gap: 10px);
                flex-direction: column;
                li {
                  width: 100%;
                  font-size: 1.8rem;
                  a {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    color: var(--secondaryColor);
                    &:hover {
                      color: var(--accentColor);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .contactPage {
    .contactSection {
      > div {
        > div {
          .contactBlock {
            > div {
              &:nth-of-type(2) {
                border-width: 1rem;
                .followus {
                  flex-wrap: wrap;
                }
              }
            }
          }
        }
      }
    }
  }
}
