@import "../../../styleFunctions/mixins";

.formBlock {
  max-width: 80rem;
  margin: 5rem auto 0;
  form {
    .fieldContainer {
      @include flexContainer;
      input,
      textarea {
        margin-bottom: 3rem;
        width: 100%;
        flex: 1 0 48%;
        padding: 1rem 1.5rem;
        border: 0.2rem solid var(--primaryColor);
        border-radius: 0.8rem;
        font-size: 1.7rem;
        transition: all 0.3s ease-in-out;
        &:focus {
          outline: none;
        }
      }
      button {
        color: var(--whiteColor);
        svg {
          fill: inherit;
        }
        &:hover {
            color: var(--primaryColor);
        }
      }
    }
    .recaptchaContainer {
      margin-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  .formBlock {
    form {
      .fieldContainer {
        flex-direction: column;
        gap: 0;
      }
    }
  }
}
