@import "../../../styleFunctions/mixins";

.pricingBox {
  .pricingLabel {
    @include flexContainer($gap: 5px);
    h3,
    p {
      margin: 0;
    }
    p {
      color: var(--accentColor);
    }
    h3 {
      letter-spacing: 2.1px;
      font-weight: 400;
      font-size: 20px;
      text-align: left;
    }
    span {
      flex: 1 1 auto;
      border-top: 2px dotted var(--primaryColor);
      height: 2px;
      align-self: flex-end;
    }
  }
  > p {
    text-align: left;
    margin: 10px 0 0;
    font-size: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .pricingBox {
    .pricingLabel {
      h3 {
        font-size: 18px;
        line-height: 29px;
      }
    }
  }
}
