@import "../../styleFunctions/mixins";

.takeOutPage {
  .menuFirstSection {
    > div {
      > div {
        padding-bottom: 10rem;
        &:nth-of-type(1) {
          row-gap: 40px;
          > div {
            &:nth-of-type(1) {
              @include flexContainer($gap: 80px, $alignItems: flex-start);
              flex-wrap: wrap;
              > div {
                flex: 1 0 500px;
              }
            }
          }
        }
        &:nth-of-type(2) {
          padding-top: 0px;
          row-gap: 40px;
          > div {
            &:nth-of-type(2) {
              @include flexContainer($gap: 80px, $alignItems: flex-start);
              flex-wrap: wrap;
              > div {
                flex: 1 0 500px;
              }
            }
          }
        }
        h2 {
          text-align: center;
        }
        .subTitle {
          color: var(--accentColor);
          font-size: 2.5rem;
        }
        p {
          text-align: center;
          max-width: 80%;
          margin: 0 auto;
        }
        .btnContainer {
          @include flexContainer;
          margin: 40px auto 0;
          max-width: 40%;
          a {
            width: 100%;
            // display: block;
            text-align: center;
            font-size: 2rem;
          }
        }
        .ourStorySection {
          display: flex;
          justify-content: center;
          align-items: stretch;
          gap: 40px;
          margin-top: 60px;
          > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;
            background-color: var(--primaryColor);
            border-radius: 0.8rem;
            padding: 3rem;
            &:nth-of-type(3),
            &:nth-of-type(2) {
              svg {
                width: 80px;
                height: 80px;
              }
            }
            > div {
              width: 100%;
              text-align: center;
            }
            h2 {
              font-size: 4rem;
            }
            h2,
            p {
              color: var(--secondaryColor);
              margin: 0 auto;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .takeOutPage {
    .menuFirstSection {
      > div {
        > div {
          &:nth-of-type(1) {
            > div {
              &:nth-of-type(1) {
                > div {
                  flex: unset;
                  width: 100%;
                }
              }
            }
          }
          &:nth-of-type(2) {
            > div {
              &:nth-of-type(2) {
                > div {
                  flex: unset;
                }
              }
            }
          }
          p {
            max-width: 100%;
          }
          .ourStorySection {
            flex-direction: column;
          }
        }
      }
    }
  }
}
