@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

:root {
  --primaryColor: #000;
  --secondaryColor: #fff;
  --accentColor: #e81d26;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Josefin Sans', sans-serif;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}

img {
  width: 100%;
}

ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

/* Active Nav Link */

.active {
  position: relative;
  display: block;
  &:after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: var(--whiteColor);
    bottom: -0.3rem;
    left: 0;
    transform: scale(1) !important;
  }
}
