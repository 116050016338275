@import '../../styleFunctions/mixins';

.contactPage {
  margin-top: -12.6rem;
  .contactSection {
    @include backgroundImage($image: url(../../assets/contactBg.jpg));
    background-attachment: fixed;
    @include flexContainer($gap: 0);
    min-height: 90rem;
    > div {
      padding-block-start: 10rem;
      padding-block-end: 10rem;
      > div {
        justify-content: center;
        .contactBlock {
          @include flexContainer;
          background-color: var(--secondaryColor);
          width: 100%;
          max-width: 500px;
          > div {
            width: 100%;
            &:nth-of-type(1) {
              flex: 1 0 300px;
              background-color: var(--whiteColor);
              padding: 3rem;
              min-height: 60rem;
              .smallTxt {
                font-size: 1.4rem;
                color: var(--primaryColor);
              }
              h2 {
                position: relative;
                color: var(--primaryColor);
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .contactPage {
    .contactSection {
      > div {
        > div {
          .contactBlock {
            > div {
              &:nth-of-type(2) {
                border-width: 1rem;
                .followus {
                  flex-wrap: wrap;
                }
              }
            }
          }
        }
      }
    }
  }
}
