@import "../../styleFunctions/mixins";

.aboutPage {
  .aboutFirstSection {
    > div {
      > div {
        padding-bottom: 10rem;
        h2 {
          text-align: center;
        }
        .subTitle {
          color: var(--accentColor);
          font-size: 2.5rem;
        }
        p {
          text-align: center;
          max-width: 80%;
          margin: 0 auto;
        }
        .btnContainer {
          @include flexContainer;
          margin: 40px auto 0;
          max-width: 40%;
          a {
            width: 100%;
            // display: block;
            text-align: center;
            font-size: 2rem;
          }
        }
        .ourStorySection {
          display: flex;
          justify-content: center;
          align-items: stretch;
          gap: 40px;
          margin-top: 60px;
          > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 20px;
            background-color: var(--primaryColor);
            border-radius: 0.8rem;
            padding: 3rem;
            &:nth-of-type(3),
            &:nth-of-type(2) {
              svg {
                width: 80px;
                height: 80px;
              }
            }
            > div {
              width: 100%;
              text-align: center;
            }
            h2 {
              font-size: 4rem;
            }
            h2,
            p {
              color: var(--secondaryColor);
              margin: 0 auto;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  .ownerImageSection {
    @include flexContainer($gap: 0);
    > div {
      > div {
        padding-bottom: 10rem;
        // .ownerImgCol {
        //   width: 100%;
        // }
      }
    }
  }
  .storySection {
    > div {
      padding-bottom: 10rem;
      > div {
        &:nth-of-type(1) {
          margin-bottom: 30px;
        }
        &:nth-of-type(2) {
          padding-top: 0px;
        }
        video {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .ownaFranchise {
    > div {
      > div {
        &:nth-of-type(1) {
          padding-top: 0;
        }
        &:nth-of-type(2) {
          padding-top: 4rem;
          padding-bottom: 10rem;
          > div {
            padding: 20px;
            h2 {
              text-align: left;
            }
            img {
              width: auto;
            }
            &:nth-of-type(2) {
              text-align: center;
            }
          }
        }
        h2,
        p {
          text-align: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .aboutPage {
    .aboutFirstSection {
      > div {
        > div {
          p {
            max-width: 100%;
          }
          .btnContainer {
            flex-direction: column;
            max-width: 100%;
          }
          .ourStorySection {
            flex-direction: column;
          }
        }
      }
    }
    .ownerImageSection {
      padding-inline: 3rem;
      > div {
        > div {
          gap: 20px;
          padding-bottom: 0;
        }
      }
    }
    .ownaFranchise {
      img {
        width: 100% !important;
      }
    }
  }
}
