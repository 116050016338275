@import "../../../styleFunctions/mixins";

.servicePriceBox {
  @include flexContainer($justifyContent: flex-start);
  flex-direction: column;
  > div {
    width: 100%;
    h3 {
      text-align: left;
      font-size: 25px;
      letter-spacing: 1.5px;
    }
  }
  .servicePrice {
    @include flexContainer($justifyContent: flex-start);
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
}
