@import "../../../styleFunctions/mixins";

footer {
  background-color: #000;
  padding: 7rem 3rem 4rem;
  > div {
    .footerInner {
      > div {
        padding: 2rem;
        h2 {
          font-size: 2rem;
          margin: 0;
          position: relative;
          color: var(--secondaryColor);
          &:after {
            content: "";
            width: 100%;
            max-width: 10rem;
            height: 0.2rem;
            background-color: var(--secondaryColor);
            position: absolute;
            bottom: -1rem;
            left: 0;
          }
        }
        p {
          color: var(--secondaryColor);
          font-size: 1.6rem;
        }
        a {
          color: var(--secondaryColor);
          font-size: 1.6rem;
          &:hover {
            color: var(--accentColor) !important;
          }
        }
      }
      .footerColOne {
        p {
          font-size: 1.4rem;
          font-weight: 400;
        }
        .footerSiteLogo {
          margin-bottom: 2rem;
          img {
            max-width: 15rem;
          }
        }
      }
      .footerColTwo {
        .footerProductList {
          margin-top: 3rem !important;
          @include flexContainer($gap: 10px);
          flex-direction: column;
          li {
            width: 100%;
            color: var(--secondaryColor);
            font-size: 1.4rem;
            a {
              display: flex;
            align-items: center;
            gap: 10px;
            }
          }
        }
      }
      .footerColThree {
        .hoursBlock {
          margin-block: 3rem !important;
          @include flexContainer($gap: 10px);
          flex-direction: column;
          > div {
            width: 100%;
            color: var(--secondaryColor);
            font-size: 1.4rem;
            display: flex;
            align-items: center;
            gap: 10px;
            p {
              width: 100%;
              font-size: 1.4rem;
              font-weight: 400;
            }
          }
        }
      }
      .footerColFour {
        .contactDetailsBox {
          margin-block: 3rem !important;
          @include flexContainer($gap: 10px);
          flex-direction: column;
          li {
            width: 100%;
            color: var(--secondaryColor);
            font-size: 1.4rem;
            a {
              display: flex;
            align-items: center;
            gap: 10px;
            }
          }
        }
        .socialIconBox {
          margin-top: 3rem !important;
          @include flexContainer($gap: 20px);
          flex-direction: column;
          > div {
            width: 100%;
            &:nth-of-type(1) {
              display: flex;
              align-items: center;
              gap: 20px;
              a {
                color: var(--secondaryColor);
                &:hover {
                  color: var(--accentColor) !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .bottomFooter {
    border-top: 1px solid #2E2E2E;
    padding-top: 3rem;
    p {
      font-size: 1.4rem;
      font-weight: 400;
      margin: 0;
      color: var(--secondaryColor);
    }
    > div {
      > div {
        &:nth-of-type(2) {
          p {
            text-align: right;
            a {
              color: var(--accentColor);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  footer {
    > div {
      .footerInner {
        > div {
          padding: 3rem 0 0;
          &:nth-of-type(1) {
            padding-top: 0;
          }
        }
      }
    }
    .bottomFooter {
      p {
        text-align: center;
      }
      > div {
        > div {
          &:nth-of-type(2) {
            p {
              text-align: center;
              margin-top: 2rem;
            }
          }
        }
      }
    }
  }
}