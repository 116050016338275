@import "../../styleFunctions/mixins";

.contactPage {
  margin-top: -12.6rem;
  .contactSection {
    @include backgroundImage($image: url(../../assets/contactBg.jpg));
    background-attachment: fixed;
    @include flexContainer($gap: 0);
    min-height: 90rem;
    > div {
      padding-block-start: 10rem;
      padding-block-end: 10rem;
      > div {
        justify-content: center;
        .contactBlock {
          @include flexContainer;
          background-color: var(--secondaryColor);
          width: 100%;
          max-width: 80%;
          > div {
            width: 100%;
            position: relative;
            > a {
              position: absolute;
              top: 2rem;
              right: 2rem;
              padding: 0.5rem 1rem;
              font-size: 14px;
            }
            &:nth-of-type(1) {
              flex: 1 0 300px;
              background-color: var(--whiteColor);
              padding: 3rem;
              min-height: 60rem;
              // .smallTxt {
              //   font-size: 1.4rem;
              //   color: var(--primaryColor);
              // }
              h2 {
                position: relative;
                color: var(--primaryColor);
                font-size: 3rem;
              }
            }
            .smallTxt {
              font-size: 1.8rem;
              color: var(--accentColor);
            }
            .offersContainer {
              @include flexContainer($alignItems: flex-start, $gap: 6rem);
              flex-direction: column;
              > div {
                &:nth-of-type(1) {
                  > div {
                    > div {
                      > img {
                        max-width: 60%;
                        display: block;
                        margin: 0 auto;
                      }
                    }
                  }
                }
                &:nth-of-type(2) {
                  > div {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 2rem;
                  }
                }
                width: 100%;
                .offersImageContainer {
                  @include flexContainer;
                  // flex-wrap: wrap;
                  .offersImage {
                    // flex: 0 0 calc(30% - 2rem);
                    > h5 {
                      font-size: 2rem;
                    }
                  }
                }
                // > ul {
                //   @include flexContainer;
                //   flex-direction: column;
                //   > li {
                //     width: 100%;
                //   }
                // }
              }
            }
            .offerTable {
              @include flexContainer($alignItems: flex-start);
              gap: 2rem;
              margin-top: 4rem;
              > div {
                width: 100%;
                h5 {
                  font-size: 2rem;
                  color: var(--primaryColor);
                }
                ul {
                  @include flexContainer($alignItems: flex-start);
                  flex-direction: column;
                  gap: 1rem;
                  > li {
                    h5 {
                      font-size: 1.8rem;
                      color: var(--primaryColor);
                    }
                    p {
                      font-size: 1.6rem;
                      color: var(--accentColor);
                      font-weight: 600;
                    }
                  }
                }
              }
            }
            .offerNote {
              font-size: 1.4rem;
              color: var(--accentColor);
              font-weight: 600;
              margin-block: 2rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  .contactPage {
    .contactSection {
      > div {
        > div {
          .contactBlock {
            max-width: 100%;
            > div {
              .offersContainer {
                > div {
                  &:nth-of-type(2) {
                    > div {
                      grid-template-columns: repeat(2, 1fr);
                    }
                  }
                }
              }
              .offerTable {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}
