@import "./styleFunctions/mixins";

main {
  @include flexContainer(
    $alignItems: flex-start,
    $justifyContent: flex-start,
    $gap: 0
  );
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  header,
  footer {
    width: 100%;
  }
  > div {
    flex: 1 1 auto;
    width: 100%;
  }
}

.mobileMenuBlock {
  .active {
    border-bottom: 0.2rem solid var(--accentColor);
  }
}

.sectionContainer {
  > div {
    padding: 12rem 3rem 0rem;
  }
}

.disabledBtn {
  pointer-events: none;
  background-color: grey !important;
  border: none !important;
}

.promotionSliderContainer {
  .slick-track {
    @include flexContainer;
  }
}

.popupBgLayer {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999;
}

@media only screen and (max-width: 900px) {
  header {
    z-index: 2;
  }
}

@media only screen and (max-width: 600px) {
  .sectionContainer {
    > div {
      padding: 10rem 3rem 0rem;
      > div {
        padding: 0;
      }
    }
  }
}