@import "../../../styleFunctions/mixins";

header {
  // .topHeader {
  //   @include flexContainer($justifyContent: space-between);
  //   background-color: var(--primaryColor);
  //   padding-inline: 4rem;
  //   height: 5rem;
  //   > div {
  //     width: 100%;
  //   }
  //   .contactInfo {
  //     @include flexContainer($justifyContent: flex-start, $gap: 3rem);
  //     > a {
  //       transition: all 0.2s ease-in-out;
  //       display: flex;
  //       align-items: center;
  //       gap: 10px;
  //       line-height: normal;
  //       color: var(--secondaryColor);
  //       font-size: 1.6rem;
  //       &:hover {
  //         color: var(--accentColor) !important;
  //       }
  //     }
  //   }
  //   .socialIcons {
  //     @include flexContainer($justifyContent: flex-end);
  //     > a {
  //       transition: all 0.2s ease-in-out;
  //       color: var(--secondaryColor);
  //       font-size: 1.6rem;
  //       &:hover {
  //         color: var(--accentColor) !important;
  //       }
  //     }
  //   }
  // }
  .bottomHeader {
    @include flexContainer($justifyContent: flex-start, $gap: 0);
    background-color: transparent;
    position: sticky;
    width: 100%;
    top: 0;
    height: 12.4rem;
    z-index: 12;
    padding-top: 1rem;
    .logoContainer {
      a {
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          max-width: 10rem;
          // border-radius: 50%;
        }
        h2 {
          font-size: 2.6rem;
          line-height: 3.8rem;
          margin: 0;
        }
      }
    }
    > div {
      > div {
        align-items: center;
        .mainMenu {
          @include flexContainer($gap: 5rem, $justifyContent: flex-end);
          li {
            font-size: 1.6rem;
            font-weight: 500;
            color: var(--secondaryColor);
            a {
              color: inherit;
              text-transform: uppercase;
              position: relative;
              &:after {
                content: "";
                width: 100%;
                height: 2px;
                position: absolute;
                background-color: var(--whiteColor);
                bottom: -0.4rem;
                left: 0;
                transform: scale(0);
                transition: all 0.2s ease-in-out;
              }
              &:hover {
                &:after {
                  transform: scale(1);
                  transform-origin: center;
                }
              }
            }
            a {
              text-transform: uppercase;
              font-size: 1.6rem;
              background-color: transparent;
              @include flexContainer($gap: 10px);
            }
          }
          .innerChildList {
            position: relative;
            &:hover {
              .subMenu {
                display: block;
              }
            }
            .subMenu {
              background-color: var(--secondaryColor);
              padding: 1rem !important;
              border-radius: 0.8rem;
              position: absolute;
              width: 15rem;
              display: none;
              left: 50%;
              transform: translateX(-50%);
              li {
                color: var(--primaryColor);
                a {
                  font-size: 1.4rem;
                }
              }
            }
          }
        }
        .headerColThree {
          @include flexContainer($justifyContent: flex-end, $gap: 2rem);
          position: relative;
          ul {
            @include flexContainer($justifyContent: flex-end, $gap: 2rem);
            li {
              color: var(--whiteColor);
              font-size: 1.4rem;
              font-weight: 500;
              @include flexContainer($justifyContent: flex-end, $gap: 0.8rem);
            }
          }
        }
      }
    }
  }
  .mobileHeader {
    display: none;
    transition: all 0.5s ease-in-out;
  }
}

@media only screen and (max-width: 1280px) {
  header {
    .bottomHeader,
    .topHeader {
      display: none;
    }
    .mobileHeader {
      @include flexContainer($justifyContent: space-between);
      background-color: var(--secondaryColor);
      position: relative;
      height: 10rem;
      padding-inline: 4rem;
      > div {
        &:nth-of-type(3) {
          label {
            margin: 0 0 0 auto;
          }
        }
      }
      h1 {
        color: #fff;
        font-size: 5rem;
        text-align: center;
      }
      .mobileLogo {
        img {
          max-width: 12rem;
          // border-radius: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 590px) {
  header {
    z-index: 9999999;
    .mobileHeader {
      > div {
        width: 100%;
        &:nth-of-type(1) {
          display: none;
        }
      }
      .mobileLogo {
        img {
          max-width: 7rem;
        }
      }
    }
  }
}
