@import "../../styleFunctions/mixins";

.homePage {
  margin-top: -12.6rem;
  .bannerSection {
    position: relative;
    min-height: 75rem;
    @include flexContainer;
    .bannerContent {
      width: 100%;
      position: relative;
      z-index: 2;
      padding-inline: 3rem;
      > div {
        > div {
          text-align: center;
        }
        .bannerBtn {
          @include flexContainer($alignItems: stretch);
          margin-top: 40px;
          a {
            width: 100%;
            max-width: 250px;
            &:nth-of-type(2) {
              @include flexContainer($gap: 10px);
              line-height: normal;
            }
          }
        }
      }
      h2 {
        margin-bottom: 0;
        font-size: 6.5rem;
        color: var(--secondaryColor);
        text-align: center;
        text-transform: uppercase;
        line-height: 8rem;
        span {
          font-family: "Satisfy";
          font-size: 1.6rem;
        }
      }
      p {
        margin: 1rem auto 5rem;
        font-size: 2rem;
        font-weight: 400;
        color: var(--secondaryColor);
        text-align: center;
        max-width: 80rem;
      }
      // button {
      //   background-color: var(--secondaryColor);
      //   border-color: var(--secondaryColor);
      //   color: var(--primaryColor);
      // }
    }
  }
  .promotionContainer {
    position: relative;
    > div {
      z-index: 2;
      position: relative;
      > div {
        padding-bottom: 10rem;
        h2 {
          color: var(--secondaryColor);
        }
        .promotionSliderContainer {
          h2 {
            color: var(--accentColor);
            font-weight: 700;
          }
        }
      }
    }
    &:before {
      content: "";
      width: 100%;
      // max-width: 45%;
      height: 100%;
      background-color: var(--primaryColor);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .reserveDeliverySection {
    @include backgroundImage($image: url(../../assets/bgLeaf.jpg));
    background-attachment: fixed;
    position: relative;
    padding-block: 12rem;
    h2,
    p {
      color: var(--secondaryColor);
    }
  }
  .takeOutSection {
    z-index: 3;
    position: relative;
    > div {
      > div {
        padding-top: 0rem;
        > div {
          padding: 20px;
          &:nth-of-type(1) {
            @include flexContainer;
          }
        }
        .takeOutContent {
          width: 100%;
          p {
            margin: 3rem 0;
          }
        }
      }
    }
  }
  .reserveTableSection {
    z-index: 3;
    position: relative;
    > div {
      > div {
        padding-bottom: 0rem;
        > div {
          padding: 20px;
          &:nth-of-type(2) {
            @include flexContainer;
          }
        }
        .takeOutContent {
          width: 100%;
          p {
            margin: 3rem 0;
          }
        }
      }
    }
  }
  .whyChooseUsSection {
    background-color: var(--primaryColor);
    @include flexContainer($gap: 0rem);
    > div {
      width: 100%;
      min-height: 60rem;
      position: relative;
      .whyChooseContent {
        @include flexContainer($gap: 0rem);
        flex: 1;
        flex-direction: column;
        padding: 2rem;
        p,
        h2 {
          color: var(--secondaryColor);
          text-align: center;
          width: 100%;
          margin: 0;
        }
        h2 {
          font-size: 2rem;
          line-height: 3rem;
        }
        p {
          font-size: 1.4rem;
        }
        > div {
          margin: 1rem auto;
          width: 100%;
        }
      }
      .whyChoose_img {
        min-height: 300px;
      }
    }
    .whyChoose_one {
      @include backgroundImage($image: url(../../assets/whyChoose_one.jpg));
      @include flexContainer($gap: 0rem);
      flex: 1 0 500px;
      padding-inline: 2rem;
      > div {
        z-index: 2;
        text-align: center;
      }
      h2 {
        color: var(--secondaryColor);
        font-size: 8rem;
        text-align: center;
      }
    }
    .whyChoose_two {
      @include flexContainer($gap: 0, $justifyContent: space-between);
      flex-direction: column;
      > div {
        width: 100%;
        // flex: 0 1 50%;
        // min-height: 207px;
      }
      .whyChoose_img {
        @include backgroundImage($image: url(../../assets/whyChoose_two.jpg));
      }
    }
    .whyChoose_three {
      @include flexContainer($gap: 0, $justifyContent: space-between);
      flex-direction: column;
      > div {
        width: 100%;
      }
      .whyChoose_img {
        @include backgroundImage($image: url(../../assets/whyChoose_three.jpg));
      }
    }
    .whyChoose_four {
      @include flexContainer($gap: 0, $justifyContent: space-between);
      flex-direction: column;
      > div {
        width: 100%;
      }
      .whyChoose_img {
        @include backgroundImage($image: url(../../assets/whyChoose_four.jpg));
      }
    }
    .whyChoose_five {
      @include flexContainer($gap: 0, $justifyContent: space-between);
      flex-direction: column;
      > div {
        width: 100%;
      }
      .whyChoose_img {
        @include backgroundImage($image: url(../../assets/whyChoose_two.jpg));
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .homePage {
    .bannerSection {
      .bannerContent {
        margin-top: 100px;
        h2 {
          font-size: 4.5rem;
          line-height: 5.5rem;
        }
        > div {
          > div {
            .bannerBtn {
              flex-direction: column;
              a {
                max-width: 100%;
              }
            }
          }
        }
      }
    }
    .promotionContainer {
      &::before {
        max-width: 100% !important;
        // max-height: 50%;
      }
    }
    .whyChooseUsSection {
      flex-direction: column;
    }
  }
}
