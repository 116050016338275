@import "../../../styleFunctions/mixins";

.promotionCard {
    @include flexContainer;
    background-color: var(--primaryColor);
    flex-direction: column;
    padding: 0rem 3rem;
    border-radius: 1rem;
    h2, p {
        width: 100%;
        color: var(--secondaryColor);
        text-align: center;
        margin: 0;
    }
    h2 {
        font-size: 4.5rem;
    }
    p {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 600px) {
    .promotionCard {
        padding: 1rem 0 0;
    }
}