@import "../../../../styleFunctions/mixins";

.contactSection {
  background-color: var(--bgColor);
  > div {
    > div {
      padding-bottom: 10rem;
      .contactDetails {
        @include flexContainer;
        flex-direction: column;
        .contactBox {
          width: 100%;
          background-color: var(--secondaryColor);
          padding: 4rem;
          border-radius: 2rem;
          h2 {
            font-size: 3rem;
          }
          .contactDetailsBox {
            margin-block: 3rem !important;
            @include flexContainer($gap: 10px);
            flex-direction: column;
            li {
              width: 100%;
              color: #7f7f7f;
              font-size: 1.8rem;
              a {
                display: flex;
                align-items: center;
                gap: 10px;
                svg {
                  fill: var(--primaryColor);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .contactSection {
    > div {
      > div {
        > div {
          &:nth-of-type(2) {
            margin-top: 3rem;
          }
        }
        .contactDetails {
          .contactBox {
            padding: 2rem;
            .contactDetailsBox {
              li {
                a {
                  svg {
                    flex: 0 0 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
