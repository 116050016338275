@import "../../../styleFunctions/mixins";

.faqBox {
  @include flexContainer($gap: 4rem);
  flex-direction: column;
  width: 100%;
  background-color: var(--secondaryGreyColor);
  padding: 2rem;
  border-bottom: 1px solid #000;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  .faqHeader {
    @include flexContainer;
    width: 100%;
    background: none;
    border: none;
    > span {
      flex: 1 0 40%;
      font-weight: 500;
      &:nth-of-type(1) {
        text-align: left;
        font-size: 2rem;
      }
      &:nth-of-type(2) {
        text-align: right;
        svg {
          transform: rotate(270deg);
        }
      }
    }
  }
  .faqBody {
    width: 100%;
    p {
      margin: 0;
      font-size: 1.6rem;
    }
    ul {
      @include flexContainer($gap: 10px);
      flex-direction: column;
      margin-top: 1.5rem !important;
      li {
        width: 100%;
        font-size: 1.6rem;
      }
    }
  }
}
.activeFaq {
  .faqHeader {
    > span {
      &:nth-of-type(2) {
        svg {
          transform: rotate(0deg) !important;
        }
      }
    }
  }
}
